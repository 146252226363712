export const ManageCommoditiesConstants = {
    manageCommoditiesTab: 'Manage Commodities',
    adjustmentHistoryTab: 'Adjustment History',
    manageContractsTab: 'Manage Contracts',
    openScaleTicketsTab: 'Open Scale Tickets',
    createCommodity: 'Create Commodity',
    checkbox: {
        showInactive: 'Show Inactive',
    },
    button: {
        createNewCommodity: 'Create New Commodity',
        addAdjustment: 'Add Adjustment',
        commodityPricingReport: 'Commodity Pricing Report',
    },
    list: {
        limit: 20,
        offset: 0,
    },
    commodityPricingReport: {
        reportName: 'Commodity Pricing Report',
        sheetName: 'Commodity Pricing Records',
        fileName: 'Commodity Pricing Report',
        messages: {
            error: 'Error retrieving commodity pricing report.',
            started: 'Retrieving commodity pricing report...',
            success: 'Commodity pricing report downloaded.',
        },
    },
};

export const CommoditiesListConstants = {
    tableHeaders: [
        'Commodity',
        'Dry Matter',
        'Total Weight',
        'Price',
        'Price Effective Date',
        'Status',
    ],
};

export const ManageCommoditiesModalConstants = {
    title: {
        createNewCommodity: 'Create New Commodity',
        updateCommodity: 'Update Commodity',
        commodityAdjustmentHistory: 'Commodity Adjustment History',
    },
    table: {
        title: 'Commodity Adjustment History',
    },
    checkbox: {
        setActive: 'Active',
    },
    apiFields: {
        name: 'name',
        dryMatterPercentage: 'dryMatterPercentage',
        priceEffectiveDate: 'priceEffectiveDate',
        status: 'status',
        price: 'price',
    },
    commodityAdjustmentHistoryTableHeaders: [
        'Price Effective Date',
        'Dry Matter',
        'Price (per lb)',
        'Updated By',
        'Date Updated',
    ],
    mandatoryFields: [
        'name',
        'dryMatterPercentage',
        'priceEffectiveDate',
        'price',
        'tankId',
    ],
    labels: {
        commodityName: 'Commodity Name',
        price: 'Price',
        dryMatter: 'Dry Matter %',
        priceEffectiveDate: 'Price Effective Date',
        active: 'Active',
        commodityVersion: 'Commodity Version: ',
        tank: 'Tank',
    },
    placeholders: {
        commodityName: 'Enter Commodity Name',
        price: 'Enter Price',
        dryMatter: 'Enter Dry Matter %',
        priceEffectiveDate: 'Select Date',
        tank: 'Select Tank',
    },
    footer: {
        cancel: 'Cancel',
        submit: 'Submit',
    },
    successMessage: {
        createCommodity: 'Commodity Created Successfully.',
        updateCommodity: 'Commodity Updated Successfully.',
    },
};

export const AdjustmentHistoryListConstants = {
    tableHeaders: [
        'Date',
        'Commodity',
        'Dry Matter %',
        'Total Weight',
        'Actual Weight',
        'Variance',
        'Cost',
        'Comments',
    ],
};

export const ContractManagementListConstants = {
    tableHeaders: [
        'Contract Name',
        'Status',
        'Commodity',
        'Vendor',
        'Payee',
        'Price (per ton)',
        'Total (tons)',
        'Remaining (tons)',
    ],
    filterFields: {
        contractName: 'contractName',
        commodity: 'commodity',
        vendor: 'vendor',
        payee: 'payee',
        status: 'status',
    },
};

export const OpenScaleTicketsListConstants = {
    buttons: {
        assignContract: 'Assign Contract',
        clearFilters: 'Clear Filters',
        assignDisabled:
            'Selected scale tickets must be of the same type, commodity, and vendor to assign a contract.',
    },
    labels: {
        amountToAssign: 'Amount to Assign (tons)',
    },
    assignContractModal: {
        assignSuccess: 'Contract assigned successfully.',
        labels: {
            assignContract: 'Assign Contract',
            contractName: 'Contract Name',
            quantity: 'Quantity (tons)',
            price: 'Price (per ton)',
            vendor: 'Vendor',
            commodity: 'Commodity',
            multiple: 'Multiple',
        },
        buttons: {
            confirmAssignment: 'Submit',
            cancelAssignment: 'Cancel',
        },
    },

    tableHeaders: [
        'Vendor',
        'Commodity',
        'Ticket Date',
        'Scale Ticket',
        'Bill of Lading',
        'Net Weight',
        'Assign',
    ],
    filterFields: {
        scaleTicket: 'scaleTicket',
        commodity: 'commodity',
        vendor: 'vendor',
        billOfLading: 'billOfLading',
        netWeight: 'netWeight',
    },
};

export const ContractScaleTicketListConstants = {
    noScaleTickets: 'No scale tickets found.',
    buttons: {
        clearFilters: 'Clear Filters',
    },
    errors: {
        contractScaleTicketError: 'Error fetching contract scale tickets.',
    },
    tableHeaders: [
        'Scale Ticket #',
        'Ticket Date',
        'Bill of Lading',
        'Dry Matter %',
        'DM Price (per ton)',
        'Net',
        'Net (in tons)',
    ],
    filterFields: {
        scaleTicket: 'scaleTicket',
        billOfLading: 'billOfLading',
        dmPercentageStart: 'dmPercentageStart',
        dmPercentageEnd: 'dmPercentageEnd',
    },
};

export const LbsTonsToggleSwitchConstants = {
    lbs: 'Lbs',
    tons: 'Tons',
    asteriskHeaders: [ManageCommoditiesModalConstants.labels.price],
};

export const AdjustmentConstants = {
    apiFields: {
        date: 'date',
        commodity: 'commodity',
        dryMatterPercentage: 'dryMatterPercentage',
        totalWeight: 'totalWeight',
        actualWeight: 'actualWeight',
        variance: 'variance',
        cost: 'cost',
        comments: 'comments',
    },
    rowHeaders: [
        'Commodity',
        'Date',
        'Dry Matter %',
        'Total Weight',
        'Actual Weight',
        'Variance',
        'Cost',
        'Comments',
    ],
    placeholders: {
        searchCommodity: 'Search Commodity',
    },
    backdatedUpdateWarning:
        'You have selected one or more backdated commodity adjustments - these updates may take several minutes to reflect the updated inventory.',
    successMessage: 'Commodities Adjusted Successfully',
    noHistoricDataIndicator: '--',
};
