import { Dispatch, FormEvent, SetStateAction } from 'react';
import { IAccordionComponentItemList } from '../../apps/financeManager/modules/Feedlots/Interfaces/FeedLotInterface';

export interface IAccordionComponentProps {
    itemList: any[];
    createNewUrl: string;
    noneFoundHeading: string;
    createNewHeading: string;
    detailPageBaseUrl: string;
    detailPageLinkFormatFunction?: (
        item:
            | {
                name: string;
                contact: string;
                [x: string | number | symbol]: unknown;
            }
            | IAccordionComponentItemList,
    ) => string | undefined;
    cardBodyComponent?: (
        item: IAccordionContactInfoBodyDefault,
    ) => JSX.Element | undefined;
}

export interface IAccordionContactInfoBodyDefault {
    streetAddress: string | undefined;
    city: string | undefined;
    state: string | undefined;
    postalCode: string | undefined;
    phone: string | undefined;
    email: string | undefined;
}

export interface IConfirmationModal {
    showModal: boolean;
    confirmationModalHandler: (arg: boolean) => void;
    confirmationMessage: string | JSX.Element | React.ReactNode;
    cancelLabel?: string;
    confirmLabel?: string;
    disabled?: boolean;
    activeButton?: string;
    confirmationModalOptions?: ConfirmationModalOptions;
}

export enum ConfirmationModalOptions {
    Yes = 'Yes',
    No = 'No',
    NoneSelected = 'NoneSelected',
}

export interface ConfirmMultipleNotification {
    showModal: boolean;
    notificationModalHandler: any;
    setShowNotificationModal: any;
    typeOfNotificationModal: any;
    apiModalName: any;
    selectedIdsDetail: any;
    apiUrl: string;
}

export interface IConfirmNotification {
    showModal: any;
    confirmationModalHandler: any;
    globalInfo: any;
    destinationFieldName: any;
    truckingCompanyFieldName: any;
    setShowNotificationModal: any;
    typeOfNotificationModal: any;
    apiUrl: string;
    sendCcMail?: boolean;
    onSendCcMailChange?: any;
}

export interface CustomerLots {
    customer: any;
    _lots: any;
    _customerId: any;
}

export interface IErrorModal {
    showModal: boolean;
    closeModalHandler?: (show: boolean) => void;
    errorMessage: string | JSX.Element | React.ReactNode;
    language?: string;
    buttonText?: string;
}

export interface IConfirmSubmissionModal {
    showModal: boolean;
    closeModal: () => void;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    deleteRecordHandler?: (isDelete: boolean) => void;
    title: string;
    recordDate?: Date | string;
}

export interface IInformationModal {
    showModal: boolean;
    okayModalHandler: () => void;
    informationMessage: string | React.ReactNode;
    okLabel?: string;
}

export type handleScrollFn = (e: any) => any;

export interface IScaleTicketTable {
    scaleTicket: any;
    loading: boolean;
    canCallApi: boolean;
    filter: any;
    filterHandler: any;
}

export interface ITableConstants {
    navigation?: string;
    routeParameter?: string;
    headerAlign?: string;
    isStateEnable?: boolean;
    headers: any;
}

export interface IDesktopTable {
    handleScroll: () => void;
    tableRows: any[];
    loading: boolean;
    tableConstants: ITableConstants;
    actions?: any;
    permission?: boolean;
}

export interface IDestopTableHeader {
    tableConstants: ITableConstants;
}

export interface IDesktopTableBody {
    handleScroll: () => void;
    tableRows: any[];
    loading: boolean;
    tableConstants: ITableConstants;
    actions?: any;
    headCellCount: any;
    permission?: boolean;
}

export interface IDesktopTableRow {
    tableRow: any;
    rowIndex: number;
    tableConstants: ITableConstants;
    actions?: any;
    permission?: boolean;
}

export interface ILbsTonsToggleSwitch {
    headerName: string;
    changeUnit: () => void;
}

export interface ISpinnerSubmitButton {
    disabled: boolean;
    variant?: string;
    submitPending: boolean;
    className?: string;
    buttonContent: string;
    hidden?: boolean;
    onClick: () => void;
}

export type IFeedlotId = number | string;

export interface IAddRowButton {
    onClick?: () => void;
    className?: string;
}
export interface IAddTermButton {
    onClick?: () => void;
    className?: string;
    title: string;
}

export enum ApplicationTabNames {
    FeedlotManager = 'fm',
    FinApp = 'financial',
    CattlePo = 'buyPo',
}

export enum DisplayTabNames {
    FeedlotManager = 'FM',
    Financial = 'Fin App',
    BuyPo = 'Cattle PO',
    Default = 'NextGen AgTech',
}

export enum FeedlotManagerNames {
    ABarD = 'A Bar D Cattle Feeders',
    BakerCity = 'Baker City Cattle Feeders',
    Boschma = 'Boschma Feedlot',
    CannonHill = 'Cannon Hill Cattle Feeders',
    DiamondPeak = 'Diamond Peak Cattle Feeders',
    OregonTrail = 'Oregon Trail Cattle Feeders',
    ReynoldsCreek = 'Reynolds Creek Calf Ranch',
    WeiserRiver = 'Weiser River Cattle Feeders',
    Wilder = 'Wilder Cattle Feeders',
    WilsonCreek = 'Wilson Creek Cattle Feeders',
}

export enum ShortenedFeedlotManagerNames {
    ABarD = 'AD',
    BakerCity = 'BCCF',
    Boschma = 'BOSCH',
    CannonHill = 'CH',
    DiamondPeak = 'DPCF',
    OregonTrail = 'OTCF',
    ReynoldsCreek = 'RCCR',
    WeiserRiver = 'WRCF',
    Wilder = 'WCF',
    WilsonCreek = 'WCCF',
}

export interface IWindowSize {
    innerWidth: number;
    innerHeight: number;
}

export interface IToastMessage {
    showMessage: boolean;
    message: string;
    type: string;
}

export interface IFetchFees {
    name: string;
    value: string;
}

export interface ISelectedLanguage {
    value: string;
    name: string;
}

export interface IGenericObject {
    [key: string]: number | string;
}

export interface IGenericFilter {
    [key: string]: number | string | boolean | undefined;
}

export interface IGetCutoffDateResponse {
    cutoffDate: string;
    cutoffDateIsDueToInvoice: boolean;
}

export interface IOptionArray {
    id: number;
    name: string;
    [key: string]: string | number;
}

export interface ITimePicker {
    onChange?: ((value: string) => void) | undefined;
    reset?: boolean;
    value: string;
}

export interface ICsvData {
    [key: string]: string | number | Date | null;
}

export interface IWorkSheetCols {
    [key: string]: number;
}

export interface IExcelHeader {
    [key: string]: string;
}

export type IGenericSetState<T> = Dispatch<SetStateAction<T>>;

export interface IExportCSV {
    csvData: { [sheetName: string]: ICsvData[] };
    fileName: string;
    buttonLabel?: string;
    onClick: (setLoading: IGenericSetState<boolean>) => void;
    heading?: string[];
    excelHeader?: IExcelHeader[];
    showLoading?: boolean;
    validateLoading?: boolean;
    secondaryClass?: string;
    disabled?: boolean;
}

export interface IGenericOption {
    id: number;
    name: string;
    [key: string]: string | number | boolean;
}

export interface IGenericFormConstants {
    label: string;
    isRequired?: boolean;
    type: string;
    validateType?: string;
    validateOnSubmitType?: string;
    maxLength?: number;
    fieldName: string;
    isDisable?: boolean;
    options?: IGenericOption[];
}

export interface IGenericFormState {
    [key: string]: string | number | string[] | undefined;
}

export interface IGenericErrorState {
    [key: string]: boolean;
}

export interface IDynamicForm {
    formConstants: IGenericFormConstants;
    formState: IGenericFormState;
    setFormState: (arg0: string, arg1: string | number, arg2?: string) => void;
    errorState?: IGenericErrorState;
}

export interface ILanguage {
    value: string;
    name: string;
}

export interface IOverlayTooltip {
    tooltipValue: string;
    startStrLength?: number;
    endStringLength: number;
}

export type IFormControlElement = HTMLInputElement | HTMLTextAreaElement;

export enum PenKindEnum {
    Home = 'Home',
    Hospital = 'Hospital',
    Receiving = 'Receiving',
    Buller = 'Buller',
    Railer = 'Railer',
}

export enum sideNavTabEnum {
    NotSelected = 'NotSelected',
    BuyPo = 'BuyPo',
    Financial = 'Financial',
    Fm = 'Fm',
}
