import Constants from '../../utils/Constants';
import { sideNavTabEnum } from '../../utils/Interface/CommonInterface';
import { LabelConstant } from '../../utils/LabelConstant';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../Apis/FMFeedlot/baseFMFeedlotApi';

/**
 * Application Common initial state
 */
export const COMMON_INITIAL_STATE: {
    isNavActive: boolean;
    toastMessage: { showMessage: boolean; message: string; type: string };
    windowSize: { innerWidth: number; innerHeight: number };
    feeTypes: { name: string; value: string }[];
    feeRateTypes: { name: string; value: string }[];
    selectedFeedlot: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>;
    selectedLot: string;
    showSupportModal: boolean;
    selectedLanguage: { value: string; name: string };
    currentSelectedTab: sideNavTabEnum;
} = {
    isNavActive: false,
    toastMessage: {
        showMessage: false,
        message: '',
        type: '',
    },
    windowSize: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
    },
    feeTypes: [
        { name: LabelConstant.FeeType.SELECT_FEE_TYPE, value: '' },
        {
            name: LabelConstant.FeeType.TYPE_COMMISSION,
            value: LabelConstant.FeeType.TYPE_COMMISSION,
        },
        {
            name: LabelConstant.FeeType.TYPE_FREIGHT,
            value: LabelConstant.FeeType.TYPE_FREIGHT,
        },
        {
            name: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
            value: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
        },
    ],
    feeRateTypes: [
        { name: LabelConstant.FeeRateType.SELECT_RATE_TYPE, value: '' },
        {
            name: LabelConstant.FeeRateType.TYPE_CWT,
            value: LabelConstant.FeeRateType.TYPE_CWT,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_PER_HD_CT,
            value: LabelConstant.FeeRateType.TYPE_PER_HD_CT,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_PERCENTAGE,
            value: LabelConstant.FeeRateType.TYPE_PERCENTAGE,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_PER_MILE,
            value: LabelConstant.FeeRateType.TYPE_PER_MILE,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_FLAT,
            value: LabelConstant.FeeRateType.TYPE_FLAT,
        },
    ],
    selectedFeedlot: {
        id: 0,
        label: '',
        labelColorHex: '',
        manager: '',
        timezoneInfo: '',
        tzUtcMinuteOffset: 0,
        tzUtcTimeStartOfDay: '',
    },
    selectedLot: '',
    showSupportModal: false,
    selectedLanguage: { ...Constants.Languages[0] },
    currentSelectedTab: sideNavTabEnum.NotSelected,
};
