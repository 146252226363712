import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFeedlotManagerFeatures } from './interface';

export const feedlotManagerFeatures: IFeedlotManagerFeatures = {
    isCA1234On: getFeatureFlagValue(process.env.REACT_APP_FEATURE_CA1234),
    isCA12062OnRemoveShipTotal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12062_REMOVE_SHIP_TOTAL,
    ),
    isCA11343OnShipFormUpdate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11343_SHIP_FORM_UPDATE,
    ),
    isCA11415OnDelteRationTemplate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11415_DELTE_RATION_TEMPLATE,
    ),
    isCA11342OnRealizerFormUpdate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11342_REALIZER_FORM_UPDATE,
    ),
    isCA9919On_ManageUsersPage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9919_MANAGE_USERS_PAGE,
    ),
    isCA7454On_ReceiveCattleExportModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7454_RECEIVE_CATTLE_EXPORT_MODAL,
    ),
    isCA10241On_OrderTreatmentHistoryTable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10241_ORDER_TREATMENT_HISTORY_TABLE,
    ),
    isCA6632On_ShipCattleReportsModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA6632_SHIP_CATTLE_REPORTS_MODAL,
    ),
    isCA10953On_ProcessingInInvoicePeriod: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10953_PROCESSING_IN_INVOICE_PERIOD,
    ),
    isCA9148On_GetInactiveItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9148_TREATMENT_GET_INACTIVE_ITEMS,
    ),
    isCA11982On_OnlyOpenLotMFE: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11982_ONLY_OPEN_LOT_MFE,
    ),
    isCA7794On_TreatmentHistoricalGiveRecords: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7794_TREATMENT_HISTORICAL_GIVE_RECORDS,
    ),
    isCA9886On_CommoditiesNaNFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9886_COMMODITIES_NAN_FIX,
    ),
    isCA9656On_AllowZeroProcessingDose: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9656_ALLOW_ZERO_PROCESSING_DOSE,
    ),
    isCA10695On_NamingTabs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10695_NAMING_TABS,
    ),
    isCA10397On_MortalityTreatmentHistoryModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10397_MORTALITY_TREATMENT_HISTORY_MODAL,
    ),
    isCA10156On_GeneratingInvoicesModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10156_GENERATING_INVOICES_MODAL,
    ),
    isCA10624On_FinalInvoiceDuplicateProtection: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10624_FINAL_INVOICE_DUPLICATE_PROTECTION,
    ),
    isCA10655_AssignFeedChargeDropdownFilter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10655_ASSIGN_FEED_CHARGE_DROPDOWN_FILTER,
    ),
    isCA7788On_RefundCancellationPolicy: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7788_REFUND_CANCELLATION_POLICY,
    ),
    isCA10477On_RealizerCompleteWithZeroRevenue: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10477_REALIZER_COMPLETE_WITH_ZERO_REVENUE,
    ),
    isCA8488On_LbsToTonsToggle: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8488_LBS_TO_TONS_TOGGLE,
    ),
    isCA10601On_CommodityAdjustmentFormOnlyActive: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10601_COMMODITY_ADJUSTMENT_FORM_ONLY_ACTIVE,
    ),
    isCA11811On_GenericReportsControl: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11811_GENERIC_REPORTS_CONTROL,
    ),
    isCA11604On_ScaleTicketUsesReportsApi: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11604_SCALE_TICKET_USES_REPORTS_API,
    ),
    isCA12190On_Reports_CommodityPricing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12190_REPORTS_COMMODITY_PRICING,
    ),
    isCA12027On_MortalityPDFReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12027_MORTALITY_PDF_REPORT,
    ),
    isCA11014On_FMDashboard: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11014_FM_DASHBOARD,
    ),
    isCA10679On_MortalityDetailsExport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10679_MORTALITY_DETAILS_EXPORT,
    ),
    isCA11318On_SupportModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11318_SUPPORT_MODAL,
    ),
    isCA12058On_FixRationScheduleSegmentOrders: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12058_FIX_RATION_SCHEDULE_SEGMENT_ORDERS,
    ),
    isCA12140On_DisableSaveButtonsForRationSchedule: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12140_DISABLE_SAVE_BUTTONS_FOR_RATION_SCHEDULE,
    ),
    isCA11466On_EditOwnerWhenever: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11466_EDIT_OWNER_WHENEVER,
    ),
    isCA11590_EIDIncludeMedicalItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11590_EID_INCLUDE_MEDICAL_ITEMS,
    ),
    isCA10155On_ScaleTicketDownloadFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10155_SCALE_TICKET_DOWNLOAD_FIX,
    ),
    isCA10509On_ProcessingFilterClosedLots: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA1509_PROCESSING_FILTER_CLOSED_LOTS,
    ),
    isCA11435On_EIDOnExistingLists: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11435_EID_ON_EXISTING_LISTS,
    ),
    isCA10896On_ScaleTicketDataSize: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10896_SCALE_TICKET_DATA_SIZE,
    ),
    isCA10154On_PreventDoubleSaving: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10154_PREVENT_DOUBLE_SAVING,
    ),
    isCA8055On_CancelNavigation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8055_CANCEL_NAVIGATION,
    ),
    isCA10204On_PreventDoubleSavingReceiveCattleFooter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10204_PREVENT_DOUBLE_SAVING_RECEIVE_CATTLE_FOOTER,
    ),
    isCA11351On_BackdateRealizerMissingPenError: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11351_BACKDATE_REALIZER_MISSING_PEN_ERROR,
    ),
    isCA9800On_ContractsMVP: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9800_CONTRACTS_MVP,
    ),
    isCA11760On_Reports_FilterScaleTicketsByVendor: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11760_REPORTS_FILTER_SCALE_TICKETS_BY_VENDOR,
    ),
    isCA11761On_Reports_Realizers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11761_REPORTS_REALIZERS,
    ),
    isCA11809On_Reports_Ship: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11809_REPORTS_SHIP,
    ),
    isCA11808On_Reports_Receive: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11808_REPORTS_RECEIVE,
    ),
    isCA11766On_VendorScaleTicketReportPrettyPdf: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11766_VENDOR_SCALE_TICKET_REPORT_PRETTY_PDF,
    ),
    isCA11762On_Reports_InvoicesChargeSummary: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11762_REPORTS_INVOICES_CHARGE_SUMMARY,
    ),
    isCA11763On_Reports_CommodityBilling: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11763_REPORTS_COMMODITY_BILLING,
    ),
    isCA10418On_EnhanceScaleTicketsExports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10418_ENHANCE_SCALE_TICKETS_EXPORTS,
    ),
    isCA10657On_NetworkDisplay: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10657_NETWORK_DISPLAY,
    ),
    isCA10369On_ScrollBarSupport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10369_SCROLL_BAR_SUPPORT,
    ),
    isCA11013On_TreatmentAntibioticHormoneCategories: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11013_TREATMENT_ANTIBIOTIC_HORMONE_CATEGORIES,
    ),
    isCA10517On_ClosingLotFeesEnable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10517_CLOSING_LOT_FEES_ENABLE,
    ),
    isCA10816On_PenSpecificDisableMFE: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10816_PEN_SPECIFIC_DISABLE_MFE,
    ),
    isCA10903On_AnimalStatusReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10903_ANIMAL_STATUS_REPORT,
    ),
    isCA10519On_LotHealthReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10519_LOT_HEALTH_REPORT,
    ),
    isCA11386On_EIDOnExistingReports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11386_EID_ON_EXISTING_REPORTS,
    ),
    isCA9990On_RationValidationPrecision: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9990_RATION_VALIDATION_PRECISION,
    ),
    isCA8557On_CreateNewRiderOptionForTreatment: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA8557_CREATE_NEW_RIDER_OPTION_FOR_TREATMENT,
    ),
    isCA9973On_RationScheduleDeleteBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9973_RATION_SCHEDULE_DELETE_BUG,
    ),
    isCA8496On_RealizerFormMultipleApiCalls: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8496_REALIZER_FORM_MULTIPLE_API_CALLS,
    ),
    isCA10316On_TreatmentAnimalTextInput: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10316_TREATMENT_ANIMAL_TEXT_INPUT,
    ),
    isCA10390On_TreatmentsForDeadAnimals: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10390_TREATMENTS_FOR_DEAD_ANIMALS,
    ),
    isCA8191On_DatabricksRowUpdate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8191_DATABRICKS_ROW_UPDATE,
    ),
    isCA10957On_CommodityHistoryReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10957_COMMODITY_HISTORY_REPORT,
    ),
    is8642On_MakeTreatmentProtocolsEditable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8642_MAKE_TREATMENT_PROTOCOLS_EDITABLE,
    ),
    is8643On_MakeProcessingProtocolsEditable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8643_MAKE_PROCESSING_PROTOCOLS_EDITABLE,
    ),
    isCA6629On_ChangeWorksheetCreationDate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA6629_CHANGE_WORKSHEET_CREATION_DATE,
    ),
    isCA8348On_PenHistoryModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8348_ENABLE_PEN_HISTORY_MODAL,
    ),
    isCA10247On_ChangeDoseToDosage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10247_CHANGE_DOSE_TO_DOSAGE,
    ),
    isCA8683On_AnimalIdAutocompleteDropdownFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA8683_ANIMAL_ID_AUTOCOMPLETE_DROPDOWN_FIX,
    ),
    isCA8390On_DisplayReleventBillingPeriods: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8390_DISPLAY_RELEVENT_BILLING_PERIODS,
    ),
    isCA10398On_AnimalDropdownBareText: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10398_ANIMAL_DROPDOWN_BARE_TEXT,
    ),
    isCA10448On_InvoicesValidateProcessing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10448_INVOICES_VALIDATE_PROCESSING,
    ),
    isCA8591On_DeleteGiveMedItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8591_DELETE_GIVE_MED_ITEMS,
    ),
    isCA9498On_ResolveTreatmentLotSelectionLag: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9498_RESOLVE_TREATMENT_LOT_SELECTION_LAG,
    ),
    isCA4898On_LoaderList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4898_SETTINGS_LOADER_LIST,
    ),
    isCA12159On_ReprocessDateLabelFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12159_REPROCESS_DATE_LABEL_FIX,
    ),
    isCA9089On_RestrictGiveTreatmentSameMedicalItem: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9089_RESTRICT_GIVE_TREATMENT_SAME_MEDICAL_ITEM,
    ),
    isCA11602On_ScaleTicketAutocomplete: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11602_SCALE_TICKET_AUTOCOMPLETE,
    ),
    isCA7808On_PreventFutureDateScaleTicket: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7808_PREVENT_FUTURE_DATE_SCALE_TICKET,
    ),
    isCA4913On_AfChangePerHdModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4913_AF_CHANGE_PER_HD_MODAL,
    ),
    isCA5440On_TechAndSeverityBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA5440_TECH_AND_SEVERITY_BUG,
    ),
    isCA5449On_GiveMedicalItemSelectIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA5449_GIVE_MEDICAL_ITEM_SELECT_ISSUE,
    ),
    isCA10371On_TreatmentPageTreatmentHistoryModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10371_TREATMENT_PAGE_TREATMENT_HISTORY_MODAL,
    ),
    isCA11493On_DoubleMortalityDeletion: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11493_DOUBLE_MORTALITY_DELETION,
    ),
    isCA10690On_ProcessingFilter: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10690_PROCESSING_FILTER,
    ),
    isCA10570On_VendorList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10570_SETTINGS_VENDOR_LIST,
    ),
    isCA11701ProcessingNewLotsFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11701_PROCESSING_NEW_LOTS_FIX,
    ),
    isCA10320On_EditDeletePenSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10320_SETTINGS_PEN_EDIT_DELETE,
    ),
    isCA10540AddedRationNameColumn: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10540_ADDED_RATION_NAME_COLUMN,
    ),
    isCA10543_RenamingRations: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10543_RENAMING_RATIONS,
    ),
    isCA11407OnRemoveDailyCommodityRecordsButton: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11407_REMOVE_DAILY_COMMODITY_RECORDS_BUTTON,
    ),
    isCA4888On_FMSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4888_FM_SETTINGS,
    ),
    isCA11572On_CowboyAccessToHotList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11572_COWBOY_ACCESS_TO_HOT_LIST,
    ),
    isCA11016On_EIDMortalities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11016_EID_MORTALITIES,
    ),
    isCA11672_RenameMedicalFields: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11672_RENAME_MEDICAL_FIELDS,
    ),
    isCA10876On_UploadEID: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10876_UPLOAD_EID,
    ),
    isCA10935On_TreatmentRevamp: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10935_TREATMENT_REVAMP,
    ),
    isCA11492On_OldLotsLoadOnMortalities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11492_OLD_LOTS_LOAD_ON_MORTALITIES,
    ),
    isCA10904On_EIDTreatments: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10904_EID_TREATMENTS,
    ),
    isCA11015On_EIDRealizers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11015_EID_REALIZERS,
    ),
    isCA8929On_LocalUTCDateTimeFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8929_LOCAL_UTC_DATE_TIME_FIX,
    ),
    isCA9515On_PenRationHistory_Feed: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9515_PEN_RATION_HISTORY_FEED,
    ),
    isCA11359On_MultiLineScaleTicketEnhancements: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11359_ENABLE_MULTILINE_SCALE_TICKETS,
    ),
    isCA10240On_ExportTreatmentDateFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10240_EXPORT_TREATMENT_DATE_FIX,
    ),
    isCA10895On_realizerInvalidPaymentDate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10895_REALIZER_INVALID_PAYMENT_DATE,
    ),
    isCA9537On_PenRationScheduleRowDateFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9537_PEN_RATION_SCHEDULE_ROW_DATE_FIX,
    ),
    isCA9558On_ProdAlertMortalityGet502: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9558_PROD_ALERT_MORTALITY_GET_502,
    ),
    isCA9320On_MoveCattleDivideByZeroFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9320_MOVE_CATTLE_DIVIDE_BY_ZERO_FIX,
    ),
    isCA7270On_AddGetEstimatedHeads: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7270_ADD_GET_ESTIMATED_HEADS_PENCACHE,
    ),
    isCA11576On_HotListFiltering: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11576_HOT_LIST_FILTERING,
    ),
    isCA8675On_LiquidAndMicroingredientsCommodities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8675_LIQUID_MICROINGREDIENT_COMMODITIES,
    ),
    isC9809On_LotNameFromLotInsteadOfAnimalLot: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9809_LOTNAME_FROM_LOT_INSTEAD_OF_ANIMALLOT_IN_MORTALITY_REPORT,
    ),
    isCA9638On_SplitRationAllowOnly1Feedings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9638_SPLIT_RATION_ALLOW_ONLY_1_FEEDINGS,
    ),
    isCA9574On_FeedCallDmChangeInsteadAfChange: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9574_FEED_CALL_DM_CHANGE_INSTEAD_AF_CHANGE,
    ),
    isCA9674On_LoadedCommodityReportEnhancement: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9674_LOADED_COMMODITY_REPORT_ENHANCEMENT,
    ),
    isCA10313On_HospitalPageUnassociatedHomePen: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10313_HOSPITAL_PAGE_UNASSOCIATED_HOME_PEN,
    ),
    isCA9756On_AllowSameIngredientsRationCreation: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9756_ALLOW_SAME_INGREDIENTS_MULTIPLE_TIMES_IN_RATION_CREATION,
    ),
    isCA10839On_RealizerHistoryNonHospitalBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10839_REALIZER_HISTORY_NON_HOSPITAL_BUG,
    ),
    isCA9963OnMFE2RationDropdownFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9963_MFE2_RATION_DROPDOWN_FIX,
    ),
    isCA11740_SpanMedicalItemsAndProtocolsScroll: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11740_SPAN_MEDICAL_ITEMS_AND_PROTOCOLS_SCROLL,
    ),
    isCA9698On_TreatmentProtocolGiveTableBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9698_TREATMENT_PROTOCOL_GIVE_TABLE_BUG,
    ),
    isCA10166On_NumbersWithCommas: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10166_NUMBERS_WITH_COMMAS,
    ),
    isCA11479On_UpdateScaleTicketUrl: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11479_UPDATE_SCALE_TICKET_URL,
    ),
    isCA9436On_LoaderCommodityReportEnhancement: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9436_LOADER_COMMODITY_REPORT_ENHANCEMENT,
    ),
    isCA10070OnSpiltScheduleInsertConflict: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10070_SPILT_SCHEDULE_INSERT_CONFLICT,
    ),
    isCA11600On_CommoditesLbsTonsToggle: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11600_COMMODITES_LBS_TONS_TOGGLE,
    ),
    isCA10054On_CommodityCosting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10054_COMMODITY_COSTING,
    ),
    isCA9639On_VariableSplits: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9639_VARIABLE_SPLITS,
    ),
    isCA10003OnFeedCallIncreaseSpeed: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10003_FEED_CALL_INCREASE_SPEED,
    ),
    isCA10260On_duplicateRationSchedule: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10260_DUPLICATE_RATION_SCHEDULE_ROWS,
    ),
    isCA10379On_rationScheduleRowDeleteNoDaysBug: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10379_PEN_RATION_SCHEDULE_NO_DAYS_DELETE_ROW_ISSUE,
    ),
    isCA10428On_MovementHistoryReportModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10428_MOVEMENT_HISTORY_REPORT_MODAL,
    ),
    isCA10368on_scheduleRationChangeReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10368_SCHEDULE_RATION_CHANGE_REPORT,
    ),
    isCA10366On_FeedStatus2: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10366_FEED_STATUS_2,
    ),
    isCA10472On_FeedStatus2AdvanceSorting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10472_FEED_STATUS_2_ADVANCE_SORTING,
    ),
    isCA11876On_BackdateMedicalAdjustmentsBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11876_BACKDATE_MEDICAL_ADJUSTMENTS_BUG,
    ),
    isCA11329On_RealizerMortalityAnimalsAllowed: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11329_REALIZER_MORTALITY_ANIMALS_ALLOWED,
    ),
    isCA10455On_FeedCallRationChangeReminderModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10455_FEEDCALL_RATION_CHANGE_REMINDER_MODAL,
    ),
    isCA10333ReduceInfoInRequestResponseForMFE: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE,
    ),
    isCA10586On_refactorFeedCall: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10586_REFACTOR_FEED_CALL,
    ),
    isCA11426On_ReportsRenamingRationsFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11426_RATION_NAME_VERSION_SPECIFIC_IN_FEED_REPORTS,
    ),
    isCA11828On_FixCreateRationTemplateOnRefresh: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11828_FIX_CREATE_RATION_TEMPLATE_ON_REFRESH,
    ),
    isCA11348On_FixBottleSizeValidation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11348_FIX_BOTTLE_SIZE_VALIDATION,
    ),
    isCA10751On_FeedCallSamApiForGraphAndTable: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10751_FEEDCALL_SAME_API_FOR_GRAPH_AND_TABLE,
    ),
    isCA10761On_FeedCallEstHeadCountModalFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10761_FEEDCALL_EST_HEAD_COUNT_MODAL_FIX,
    ),
    isCA11188On_MoveCattleFormReset: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11188_MOVE_CATTLE_FORM_RESET,
    ),
    isCA10819On_FeedCallDmChangeHdAppearAfChangeModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10819_FEEDCALL_DM_CHANGE_HD_APPEAR_AF_CHANGE_MODAL,
    ),
    isCA10959On_rtkQueryImprovements: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10959_RTK_QUERY_IMPROVEMENTS,
    ),
    isCA11189On_FeedCallRationScheduleModalFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11189_FEEDCALL_RATION_SCHEDULE_MODAL_FIX,
    ),
    isCA10721On_MoveCattleEnhancement: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10721_MOVECATTLE_ENHANCEMENT,
    ),
    isCA11177On_FeedCallRouteAbilityAddPen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11177_FEEDCALL_ROUTE_ABILITY_ADD_PEN,
    ),
    isCA11160n_OwnerContextApiCall: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11160_OWNER_CONTEXT_API_CALL,
    ),
    isCA114950n_ReceiveCattleDateFilterFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11495_RECEIVE_CATTLE_FILTER_DATE_FILTER_FIX,
    ),
    isCA11561On_FetchRationInMfeWithDate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11561_FETCH_RATION_WITH_DATE_FOR_MFE,
    ),
    isCA10960_RationScheduleTemplateDeleteOnSave: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10960_RATION_SCHEDULE_TEMPLATE_DELETE_ON_SAVE,
    ),
    isCA11870On_RedBorderForDuplicateTemplateName: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11870_RED_BORDER_FOR_DUPLICATE_TEMPLATE_NAME,
    ),
    isCA11699On_FeedCallTabClickLoopBack: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11699_FEEDCALL_TAB_CLICK_LOOP_BACK,
    ),
    isCA11910On_VariableSplitExitingTemplateDeleteFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11910_VARIABLE_SPLIT_EXISTING_TEMPLATE_DELETE_FIX,
    ),
    isCA9481On_FeedCallBunkScoreHighlight: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9481_FEEDCALL_BUNK_SCORE_HIGHLIGHT,
    ),
};
