import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { currentDate, currentDate_deprecated } from '../../helpers';
import { IPenRationScheduleInitialState } from '../../utils/Interface/PenRationScheduleInterface';

/*
 * Create Pen Ration Schedule Initial States
 */
const { isCA8929On_LocalUTCDateTimeFix } = feedlotManagerFeatures;
export const PEN_RATION_SCHEDULE_INITIAL_STATE: IPenRationScheduleInitialState =
    {
        scheduleTypes: [{ name: 'Select Schedule Type', id: 0 }],
        rationIndex: 0,
        canCallApi: false,
        loading: false,
        penRationScheduleBasicDetail: {
            penRationScheduleTemplateId: 0,
            penRationScheduleId: 0,
            name: '',
            penName: '',
            startingDryMatterPerHd: '',
            startingAFPerHd: '',
            scheduleTypeId: 0,
            penId: 0,
        },
        penRationScheduleBasicDetailError: {
            scheduleTypeId: false,
            startingDryMatterPerHd: false,
            startingAFPerHd: false,
        },
        rations: [{ name: 'Select', id: 0 }],
        penRationSchedule: {
            id: 0,
            splitRationSegmentOrderNumber: 1,
            splitScheduleId: 0,
            ration1: 0,
            ration2: 0,
            percentRation2: 0,
            percentRation2Incr: 0,
            date: isCA8929On_LocalUTCDateTimeFix
                ? currentDate('YYYY-MM-DD')
                : currentDate_deprecated('YYYY-MM-DD'),
            days: 1,
            feedings: 1,
            isDeleted: false,
        },
        penRationScheduleErrors: [
            {
                ration1: false,
                ration2: false,
                percentRation2: false,
                percentRation2Incr: false,
                days: false,
                feedings: false,
            },
        ],
        penRationSchedules: [
            {
                id: 0,
                splitRationSegmentOrderNumber: 1,
                splitScheduleId: 0,
                ration1: 0,
                ration2: 0,
                percentRation2: 0,
                percentRation2Incr: 0,
                date: isCA8929On_LocalUTCDateTimeFix
                    ? currentDate('YYYY-MM-DD')
                    : currentDate_deprecated('YYYY-MM-DD'),
                days: 0,
                feedings: 1,
                isDeleted: false,
            },
        ],
        deletedPenRationSchedules: [],
        penInfo: {
            penId: 0,
            name: '',
        },
        penRationSelectedPenId: '',
        isPenForSelectedFeedlot: true,

        variableSplitRationSchedule: {
            id: '',
            variableSplitId: '',
            feeding1: '',
            feeding2: '',
            feeding3: '',
            rationPercentage1: '',
            rationPercentage2: '',
            rationPercentage3: '',
            percentageChange1: '',
            percentageChange2: '',
            percentageChange3: '',
            date: currentDate('YYYY-MM-DD'),
            rationScheduleSegmentOrder: 0,
            days: 1,
            isDeleted: false,
        },
        variableSplitRationSchedules: [
            {
                id: '',
                variableSplitId: '',
                feeding1: '',
                feeding2: '',
                feeding3: '',
                rationPercentage1: '',
                rationPercentage2: '',
                rationPercentage3: '',
                percentageChange1: '',
                percentageChange2: '',
                percentageChange3: '',
                date: currentDate('YYYY-MM-DD'),
                rationScheduleSegmentOrder: 0,
                days: 1,
                isDeleted: false,
            },
        ],
        deletedVariableSplitRationSchedules: [],
        variableSplitScheduleError: {
            feeding1: false,
            feeding2: false,
            feeding3: false,
            rationPercentage1: false,
            rationPercentage2: false,
            rationPercentage3: false,
            percentageChange1: false,
            percentageChange2: false,
            percentageChange3: false,
            date: false,
            days: false,
        },
        variableSplitSchedulesError: [
            {
                feeding1: false,
                feeding2: false,
                feeding3: false,
                rationPercentage1: false,
                rationPercentage2: false,
                rationPercentage3: false,
                percentageChange1: false,
                percentageChange2: false,
                percentageChange3: false,
                date: false,
                days: false,
            },
        ],
        selectedScheduleType: { scheduleTypeId: 0 },
        isPenRationScheduleTableEdited: false,
        isNewPenRationSchedule: false,
    };
