import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFinanceManagerFeatures } from './interface';

export const financeManagerFeatures: IFinanceManagerFeatures = {
    isFA201AutoSaveFeedBills: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA201_AUTO_SAVE_FEEDBILLES,
    ),
    isFA464UseDayjsInForm: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA446_USE_DAYJS_IN_FORM,
    ),
    isFA463UseDayjsInCalculation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA463_USE_DAYJS_IN_CALCULATION_FILE,
    ),
    isFA469ClosedLotsAreDisplayingTotal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA469_CLOSED_LOT_TOTAL_VALUE,
    ),
    isFA471PlusRowButtonAllowAddMultipleRow: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA471_PLUS_ROW_BUTTON_ALLOW_ADD_MULTI_ROW,
    ),
    isFA472AddSpaceBetweenHeaderInSettingPage: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA472_ADD_SPACE_IN__HEADING_IN_SETTING_PAGE,
    ),
    isFA499AddOwnerDropdownOnOwnerReportPage: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA499_ADD_OWNER_DROPDOWN_ON_OWNER_REPORT_PAGE,
    ),
    isFA500SpellMistakeAndDollarSignIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA500_SPELL_MISTAKE_IN_LOT_BALANCE_REPORT,
    ),
    isFA497LotFromAPiProdAlert: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA497_LOT_FORM_API_PROD_ALERT,
    ),
    isFA511AddQuickBookInQuarterlyReport: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA511_ADD_QUICKBOOK_NUMBER_IN_QUARTERLY_REPORT,
    ),
    isFA509showWarningForUnsavedFeedBills: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA509_SHOWING_WARNING_FOR_UNSAVED_FEEDBILLS,
    ),
    isFA519AddLimitsInFields: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA519_ADD_LIMITS_IN_FIELDS,
    ),
    isFA536UiOptimization: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA536_UI_OPTIMIZATION_WHOLE_APPLICATION,
    ),
    isFA462UseDayjsInReports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA462_USE_DAYJS_IN_Reports,
    ),
    isFA540PaginatedLotsPage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA540_PAGINATED_LOTS_PAGE,
    ),
    isFA575AddFeedlotFilterInFeedBillPage: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA575_ADD_FEEDLOT_FILTER_IN_FEEDBILLS_PAGE,
    ),
    isFA580LenderReportDateIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA580_LENDER_REPORT_DATE_FIXED,
    ),
    isFA588ChangeDateFormat: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA588_CHANGE_DATE_FORMAT,
    ),
    isFA592PaginationIssues: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA592_PAGINATION_ISSUE_FIXED,
    ),
    isFA567FeedBillPageIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA567_FEEDBILL_PAGE_FIXED,
    ),
    isFA626CattlePurchaseDuplicateIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA626_CATTLE_PURCHASE_DUPLICATE_ISSUE,
    ),
    isFA633DateValidation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA633_DATE_VALIDATION_IN_FORMS,
    ),
    isFA635HedgePageNavigation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA635_HEDGE_PAGE_NAVIGATION,
    ),
};
