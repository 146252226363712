import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deepClone } from '../../../../helpers';
import {
    IAdjustmentCommoditiesDetailsError,
    IAdjustmentCommoditiesResponse,
    IAdjustmentHistoryResponse,
    ICommoditiesResponse,
    ICommodityAdjustmentHistoryResponse,
    ICommodityDetails,
    ICommodityDetailsError,
    IContractFilter,
    IContractScaleTicket,
    IContractScaleTicketFilter,
    IContractsResponse,
    IFilter,
    IOpenScaleTicket,
    IOpenScaleTicketFilter,
    ITank,
} from '../interfaces/ManageCommoditiesInterface';
import { MANAGE_COMMODITIES_INITIAL_STATE } from '../constants/manageCommoditiesInitialState';

export const ManageCommodities = createSlice({
    name: 'ManageCommodities',
    initialState: { ...MANAGE_COMMODITIES_INITIAL_STATE },
    reducers: {
        setManageCommoditiesAdjustmentsLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.adjustmentsLoading = action.payload;
        },
        setManageCommoditiesLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.commoditiesLoading = action.payload;
        },
        setManageCommoditiesAdjustmentsCanCallApi: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.adjustmentsCanCallApi = action.payload;
        },
        setManageCommoditiesCanCallApi: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.commoditiesCanCallApi = action.payload;
        },
        setManageCommoditiesUnit: (state, action: PayloadAction<string>) => {
            state.manageCommoditiesUnit = action.payload;
        },
        setManageCommoditiesAdjustmentsFilter: (
            state,
            action: PayloadAction<IFilter>,
        ) => {
            state.adjustmentsFilter = action.payload;
        },
        setManageCommoditiesFilter: (state, action: PayloadAction<IFilter>) => {
            state.commoditiesFilter = action.payload;
        },
        setContractsFilter: (state, action: PayloadAction<IContractFilter>) => {
            state.contractsFilter = action.payload;
        },
        setCommodityAction: (state, action: PayloadAction<string>) => {
            state.commodityAction = action.payload;
        },
        setCommodityId: (state, action: PayloadAction<number>) => {
            state.commodityId = action.payload;
        },
        setCommoditiesList: (
            state,
            action: PayloadAction<ICommoditiesResponse[]>,
        ) => {
            state.commoditiesList = action.payload;
        },
        setAdjustmentHistoryList: (
            state,
            action: PayloadAction<IAdjustmentHistoryResponse[]>,
        ) => {
            state.adjustmentHistoryList = action.payload;
        },
        setAdjustmentHistoryListUnit: (
            state,
            action: PayloadAction<string>,
        ) => {
            state.adjustmentHistoryListUnit = action.payload;
        },
        setAdjustmentCommoditiesDetails: (
            state,
            action: PayloadAction<IAdjustmentCommoditiesResponse[]>,
        ) => {
            state.adjustmentCommoditiesDetails = action.payload;
        },
        setCommodityCost: (
            state,
            action: PayloadAction<IAdjustmentCommoditiesResponse>,
        ) => {
            const changedCommodity = state.adjustmentCommoditiesDetails.find(
                commodity =>
                    commodity.commodityId === action.payload.commodityId,
            );
            if (changedCommodity) {
                changedCommodity.cost = action.payload.cost;
            }
        },
        setAdjustmentCommoditiesDetailsError: (
            state,
            action: PayloadAction<IAdjustmentCommoditiesDetailsError>,
        ) => {
            state.adjustmentCommoditiesDetailsError = action.payload;
        },
        setAdjustmentCommoditiesDetailsAccordingToDate: (
            state,
            action: PayloadAction<string>,
        ) => {
            const adjustmentCommoditiesDetailsCopy = deepClone(
                state.adjustmentCommoditiesDetails,
            );

            for (const index in adjustmentCommoditiesDetailsCopy) {
                adjustmentCommoditiesDetailsCopy[index]['date'] =
                    action.payload;
            }

            state.adjustmentCommoditiesDetails =
                adjustmentCommoditiesDetailsCopy;
        },
        setFetchedAdjustmentCommoditiesDetails: (
            state,
            action: PayloadAction<IAdjustmentCommoditiesResponse[]>,
        ) => {
            state.fetchedAdjustmentCommoditiesDetails = action.payload;
        },
        setCommodityDetails: (
            state,
            action: PayloadAction<ICommodityDetails>,
        ) => {
            state.commodityDetails = action.payload;
        },
        setCommodityDetailsUnit: (state, action: PayloadAction<string>) => {
            state.commodityDetailsUnit = action.payload;
        },
        setFetchedCommodityDetails: (
            state,
            action: PayloadAction<ICommodityDetails>,
        ) => {
            state.fetchedCommodityDetails = action.payload;
        },
        setCommodityDetailsError: (
            state,
            action: PayloadAction<ICommodityDetailsError>,
        ) => {
            state.commodityDetailsError = action.payload;
        },
        setCommodityAdjustmentHistory: (
            state,
            action: PayloadAction<ICommodityAdjustmentHistoryResponse[]>,
        ) => {
            state.commodityAdjustmentHistoryList = action.payload;
        },
        setEarliestGeneratedInvoiceCutoffDate: (
            state,
            action: PayloadAction<string>,
        ) => {
            state.earliestGeneratedInvoiceCutoffDate = action.payload;
        },

        setContracts: (state, action: PayloadAction<IContractsResponse[]>) => {
            state.contracts = action.payload;
        },

        appendContracts: (
            state,
            action: PayloadAction<IContractsResponse[]>,
        ) => {
            state.contracts = [...state.contracts, ...action.payload];
        },

        setSelectedContract: (
            state,
            action: PayloadAction<{ id: string; name: string }>,
        ) => {
            state.selectedContract = action.payload;
        },

        setSelectedContractError: (state, action: PayloadAction<boolean>) => {
            state.selectedContractError = action.payload;
        },

        setContractsLoading: (state, action: PayloadAction<boolean>) => {
            state.contractsLoading = action.payload;
        },

        setOpenScaleTicketLoading: (state, action: PayloadAction<boolean>) => {
            state.openScaleTicketLoading = action.payload;
        },

        setContractScaleTickets: (
            state,
            action: PayloadAction<IContractScaleTicket[]>,
        ) => {
            state.contractScaleTickets = action.payload;
        },

        appendContractScaleTickets: (
            state,
            action: PayloadAction<IContractScaleTicket[]>,
        ) => {
            state.contractScaleTickets = [
                ...state.contractScaleTickets,
                ...action.payload,
            ];
        },

        setContractHasScaleTickets: (
            state,
            action: PayloadAction<IContractScaleTicket[]>,
        ) => {
            const tickets = action.payload;
            state.contractHasScaleTickets = tickets.length > 0;
        },

        setContractScaleTicketsLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.contractScaleTicketsLoading = action.payload;
        },

        setContractScaleTicketFilter: (
            state,
            action: PayloadAction<IContractScaleTicketFilter>,
        ) => {
            state.contractScaleTicketFilter = action.payload;
        },

        setOpenScaleTicketFilter: (
            state,
            action: PayloadAction<IOpenScaleTicketFilter>,
        ) => {
            state.openScaleTicketFilter = action.payload;
        },

        setOpenScaleTickets: (
            state,
            action: PayloadAction<IOpenScaleTicket[]>,
        ) => {
            state.openScaleTickets = action.payload;
        },

        appendOpenScaleTickets: (
            state,
            action: PayloadAction<IOpenScaleTicket[]>,
        ) => {
            state.openScaleTickets = [
                ...state.openScaleTickets,
                ...action.payload,
            ];
        },

        toggleOpenScaleTicketChecked: (
            state,
            action: PayloadAction<number[]>,
        ) => {
            state.openScaleTickets = state.openScaleTickets.map(ticket =>
                action.payload.includes(ticket.scaleTicketId)
                    ? { ...ticket, checked: !ticket.checked }
                    : ticket,
            );
        },

        setAllOpenScaleTicketsChecked: state => {
            const newCheckedState = !state.openScaleTicketsSelectAll;
            state.openScaleTicketsSelectAll = newCheckedState;
            state.openScaleTickets = state.openScaleTickets.map(ticket => ({
                ...ticket,
                checked: newCheckedState,
            }));
        },

        resetOpenScaleTicketsSelectAll: state => {
            state.openScaleTicketsSelectAll =
                MANAGE_COMMODITIES_INITIAL_STATE.openScaleTicketsSelectAll;
        },

        setTanks: (state, action: PayloadAction<ITank[]>) => {
            state.tanks = action.payload;
        },

        setCommoditiesSubmitPending: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.submitPending = action.payload;
        },
    },
});

export const {
    setManageCommoditiesAdjustmentsLoading,
    setManageCommoditiesLoading,
    setManageCommoditiesAdjustmentsCanCallApi,
    setManageCommoditiesCanCallApi,
    setManageCommoditiesUnit,
    setManageCommoditiesAdjustmentsFilter,
    setManageCommoditiesFilter,
    setContractsFilter,
    setCommodityAction,
    setCommodityId,
    setCommoditiesList,
    setAdjustmentHistoryList,
    setAdjustmentHistoryListUnit,
    setAdjustmentCommoditiesDetails,
    setCommodityCost,
    setAdjustmentCommoditiesDetailsError,
    setFetchedAdjustmentCommoditiesDetails,
    setCommodityDetails,
    setCommodityDetailsUnit,
    setFetchedCommodityDetails,
    setCommodityDetailsError,
    setCommodityAdjustmentHistory,
    setAdjustmentCommoditiesDetailsAccordingToDate,
    setEarliestGeneratedInvoiceCutoffDate,
    setContracts,
    appendContracts,
    setSelectedContract,
    setSelectedContractError,
    setContractsLoading,
    setContractScaleTickets,
    appendContractScaleTickets,
    setContractScaleTicketsLoading,
    setContractHasScaleTickets,
    setContractScaleTicketFilter,
    setOpenScaleTicketLoading,
    setOpenScaleTicketFilter,
    setOpenScaleTickets,
    appendOpenScaleTickets,
    toggleOpenScaleTicketChecked,
    setAllOpenScaleTicketsChecked,
    resetOpenScaleTicketsSelectAll,
    setTanks,
    setCommoditiesSubmitPending,
} = ManageCommodities.actions;

const manageCommoditiesReducer = ManageCommodities.reducer;
export default manageCommoditiesReducer;
