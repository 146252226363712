export const TENANT_SETTINGS_INITIAL_STATE = {
    settingVisibility: {
        Breed: {
            id: '',
            settingName: 'enableBreed',
            displayName: '',
            isActive: false,
        },
        Color: {
            id: '',
            settingName: 'enableColor',
            displayName: '',
            isActive: false,
        },
        Sex: {
            id: '',
            settingName: 'enableSex',
            displayName: '',
            isActive: false,
        },
        KillGroup: {
            id: '',
            settingName: 'enableKillGroup',
            displayName: '',
            isActive: false,
        },
        DOA: {
            id: '',
            settingName: '',
            displayName: '',
            isActive: false,
        },
        Cut: {
            id: '',
            settingName: '',
            displayName: '',
            isActive: false,
        },
        SecondBuyer: {
            id: '',
            settingName: '',
            displayName: '',
            isActive: false,
        },
        PickupDateRange: {
            id: '',
            settingName: '',
            displayName: '',
            isActive: false,
        },
        SlideUnit: {
            id: '',
            settingName: '',
            displayName: '',
            isActive: false,
        },
        WeightStops: {
            id: '',
            settingName: '',
            displayName: '',
            isActive: false,
        },
    },
    loading: false,
    filter: {
        destinationFilter: {
            show: 'active',
            name: '',
        },
        buyLocationFilter: {
            show: 'active',
            name: '',
        },
        truckingCompanyFilter: {
            show: 'active',
            name: '',
        },
        killGroupFilter: {
            show: 'active',
            name: '',
        },
        colorFilter: {
            show: 'active',
            name: '',
        },
        sexFilter: {
            show: 'active',
            name: '',
        },
        breedFilter: {
            show: 'active',
            name: '',
        },
    },
};
